<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0 accent">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span *ngIf="type !== 'store'" class="title dialog-title">Search</span>
      <span *ngIf="type === 'store'" class="title dialog-title">Search Contact</span>
      <button mat-dialog-close mat-icon-button aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-dialog-content class="p-24 pb-0 m-0" style="margin-top:25px; margin-bottom:25px">
    <input
      type="text"
      placeholder="name or email or phone no."
      aria-label="Number"
      matInput
      [formControl]="contactSearchControl"
      [matAutocomplete]="auto"
      style="border: 1px solid #e2d8d8; height: 33px;height:40px;width:90%"
    />
    <mat-icon *ngIf="isSearchLoading" style="background: #00C6D8;border-radius: 50%; margin-left: 8px;">
      <mat-spinner diameter="20"> </mat-spinner>
    </mat-icon>

    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option
        *ngFor="let contact of searchContacts | async"
        [value]="contact.label"
        (onSelectionChange)="setContactID(contact.id)"
      >
        <span>{{ contact.label }} {{ contact.email }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-dialog-content>
  <mat-divider style="margin-top:25px;margin-bottom:3px"></mat-divider>

  <mat-dialog-actions style="float:right;margin-right: 25px;">
    <!-- <button mat-raised-button mat-dialog-close tabindex="-1" style="margin-right: 10px;">cancel</button> -->
    <button [disabled]="!guId" class="mat-accent m-0" mat-raised-button tabindex="1" (click)="getContactToken()">
      Submit

      <mat-icon *ngIf="isLoading">
        <mat-spinner diameter="20"> </mat-spinner>
      </mat-icon>
    </button>
  </mat-dialog-actions>
</div>
