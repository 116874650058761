import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WindowRef } from 'app/core/services/winref.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class OnboardingService {

    constructor(
        private _windowRef: WindowRef,
        private http: HttpClient
    ) {

    }

    uploadLogo(cloudinaryName, payload) {
        const url = "https://api.cloudinary.com/v1_1/" + cloudinaryName + "/upload";

        const formData = new FormData();
        formData.append('upload_preset', payload.upload_preset);
        formData.append('tags', payload.tags);
        formData.append('context', payload.context);
        formData.append('file', payload.file);
        formData.append('folder',payload.folder);

        const headers = {
            headers:
                new HttpHeaders({
                    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryqrfYY9NCa0cIVfiU',
                    'Authorization': undefined
                })
        }

        return this.http.post(url, payload, headers);
    }

    updateTenantService(payload): Observable<any> {
        let apiUrl = `${environment.apiBaseUrl}/rest/tenant`;
        return this.http.put(apiUrl, payload);
    }

    updateTenantUserService(payload, guid): Observable<any> {
        let apiUrl = `${environment.apiBaseUrl}/rest/contact/${guid}`;
        return this.http.put(apiUrl, payload);
		}

		resendVerification(payload, guid): Observable<any> {
			let apiUrl = `${environment.apiBaseUrl}/rest/contact/${guid}/verification`;
			return this.http.put(apiUrl, payload);
		}

		resendOtpVerification(payload, guid): Observable<any> {
			let apiUrl = `${environment.apiBaseUrl}/rest/contact/${guid}/otp`;
			return this.http.put(apiUrl, payload);
		}
		
		getContactDetails(guid): Observable<any> {
			let apiUrl = `${environment.apiBaseUrl}/rest/contact/${guid}`;
			return this.http.get(apiUrl);
	}
	 
}