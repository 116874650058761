<div *ngIf="isPublicView && _tenantConfig.org.isShowFooter == false" class="top-footer"  [style.background]="_tenantConfig.org.headerColor" fxflex fxLayoutAlign="space-between">
  <div class="wrapper">
    <h3 *ngIf="_tenantConfig.org.isShowFooter == false" style="margin: 18px 0">{{ _tenantConfig.name }}</h3>

    <div [innerHTML]="_tenantConfig.org.footerInfo" id="footerMessage" *ngIf="_tenantConfig.org.isShowFooter == false && _tenantConfig.org.footerInfo && _tenantConfig.org.footerInfo !== ''" style="color: #bababa; max-width: 450px">
    </div>
  </div>

  <div class="social-icon" style="margin-top: 14px" *ngIf="_tenantConfig.org.isShowFooter == false">
    <h4 style="margin: 5px 3px 18px 0px; text-align: left" *ngIf="_tenantConfig.web && _tenantConfig.web.length > 0">Pages</h4>
    <div class="wrapper" id="pages">
      <span *ngFor="let item of pages">
        <a *ngIf="!item.isExternalLink && item.isShowFooter" [routerLink]="item.url">{{ item.title }}</a>
        <a *ngIf="item.isExternalLink && item.isShowFooter" href="{{ item.externalLink }}">{{ item.title }}</a>
      </span>
    </div>
  </div>

  <div *ngIf="_tenantConfig.org.isShowFooter == false && (_tenantConfig.org.privacyPolicyLink || _tenantConfig.org.tosLink)" class="social-icon" style="margin-top: 14px">
    <div class="wrapper" id="pages">
      <h4 style="margin: 5px 3px 18px 0px; text-align: left">Legal</h4>
      <a *ngIf="_tenantConfig.org.privacyPolicyLink" href="{{ _tenantConfig.org.privacyPolicyLink }}" target="_blank">Privacy Policy</a>
      <a *ngIf="_tenantConfig.org.tosLink" href="{{ _tenantConfig.org.tosLink }}" target="_blank">Terms Of Service</a>
    </div>
  </div>

  <div *ngIf="_tenantConfig.org.isShowFooter == false" class="address-info">
    <div *ngIf="_tenantConfig.org.isShowFooter == false">
      <h4 style="margin: 5px 3px 18px 0px; text-align: left" *ngIf="_tenantConfig.socials && _tenantConfig.socials.length > 0">Follow Us</h4>
      <div style="text-align: left; margin-bottom:15px" fxLayout="row" fxLayoutGap="5px" *ngIf="_tenantConfig.socials && _tenantConfig.socials.length > 0">
        <span *ngFor="let item of _tenantConfig.socials">
          <a href="https://www.facebook.com/{{ item.value }}" target="_blank" *ngIf="item.name === 'facebook'">
            <img src="assets/images/social-icon/{{ item.name }}-icon-small.png" style="width: 18px; height: 18px; margin-top: 3px; margin-right: 3px"
            />
          </a>
          <a href="https://www.linkedin.com/{{ item.value }}" target="_blank" *ngIf="item.name === 'linkedin'">
            <img src="assets/images/social-icon/{{ item.name }}-icon-small.png" style="width: 18px; height: 18px; margin-top: 3px; margin-right: 3px"
            />
          </a>
          <a href="https://www.instagram.com/{{ item.value }}" target="_blank" *ngIf="item.name === 'instagram'">
            <img src="assets/images/social-icon/{{ item.name }}-icon-small.png" style="width: 18px; height: 18px; margin-top: 3px; margin-right: 3px"
            />
          </a>
          <a href="https://www.twitter.com/{{ item.value }}" target="_blank" *ngIf="item.name === 'twitter'">
            <img src="assets/images/social-icon/{{ item.name }}-icon-small.png" style="width: 18px; height: 18px; margin-top: 3px; margin-right: 3px"
            />
          </a>
        </span>
      </div>
      <div fxLayout="column" class="contact-us" fxLayoutAlign="none start">
        <h4 style="margin: 5px 3px 18px 0px;">Contact us</h4>
        <div fxFlex fxLayoutAlign="none center"><mat-icon style="font-size: 20px;">call</mat-icon><span>{{ _tenantConfig.phone }}</span></div>
        <div fxFlex fxLayoutAlign="none center"><mat-icon style="font-size: 20px;">email</mat-icon><span>{{ _tenantConfig.email }}</span></div>
      </div>
    </div>
  </div>
</div>

<mat-toolbar style="margin: 0 auto">
  <div class="d-block top-footer" fxLayout="column" fxLayoutAlign.gt-xs="space-between" fxFlex *ngIf="isPublicView" style="float: right; margin: 0 auto; padding: 0">
    <div fxLayout="row" fxLayoutAlign="end center">
      <span class="username" style="font-size: 12px; font-weight: normal"
        ><a href="https://wajooba.com" target="_blank"> <img class="logo-icon" src="assets/images/logos/Wajooba_Logo_w.png" style="width: 24px;" /></a
      ></span>
      <!-- <a mat-button routerLink="/documentation/changelog">Changelog</a> -->
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex *ngIf="!isPublicView">
    <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs style>
      <span class="username mr-12" style="font-size: 12px; font-weight: normal" fxHide fxShow.gt-sm><a href="https://wajooba.com" target="_blank">Wajooba</a> © {{ envName }}</span>
      <!-- <a mat-button routerLink="/documentation/changelog">Changelog</a> -->
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" fxHide fxShow.gt-xs>
      <!-- <a mat-button routerLink="/documentation/getting-started/introduction">Documentation</a> -->
      <!-- <span>&bull;</span> -->
      <span class="username mr-12" style="font-size: 12px; margin-right: 5px; font-weight: normal" fxHide fxShow.gt-sm>
        Last account activity <mat-icon style="font-size: 12px; margin-left: 5px; margin-right: -5px">access_time</mat-icon>
        {{ lastLoginTimeStr }}
      </span>

      <!-- <a mat-button routerLink="/documentation/changelog">Changelog</a> -->
    </div>
  </div>
</mat-toolbar>
