<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
<div id="topdiv" class="topdiv" #topdiv>
  <div id="registration-success" *ngIf="formSubmited" class="secondary-text txt-color-green h1 mt-16" fxLayout="row" fxLayoutAlign="center center">
    <h3 class="secondary-text txt-color-green h1" style="margin-top: 65px">
      Thank You, &nbsp;
      <span *ngIf="!isDemoCourseFlow && formName" style="font-weight: bold"
        >{{ formName | titlecase }}
        &nbsp;submitted successfully.
      </span>
      <span *ngIf="isDemoCourseFlow && formName" style="font-weight: bold">{{ courseName | titlecase }} enrolled successfully </span>
    </h3>
  </div>
  <div *ngIf="formSubmited && isDemoCourseFlow" fxLayout="row" fxLayoutAlign="center center">
    <h3 style="text-align: center; font-size: 17px">Course details sent by email, also if you a new login details are also sent</h3>
  </div>
  <div *ngIf="formSubmited && isDemoCourseFlow" fxLayout="row" fxLayoutAlign="center center">
    <h3>Download the app on your device and login with the credentials sent on your email.</h3>
  </div>
  <div *ngIf="formSubmited && isDemoCourseFlow" fxLayout="row" fxLayoutAlign="center center">
    <a target="_blank" mat-raised-button class="reference-button mt-16 mt-sm-0" color="accent" href="https://play.google.com/store/apps/details?id=com.wajooba.mindsynckids">
      <mat-icon class="mr-8">android</mat-icon> Download App
    </a>
  </div>
  <div *ngIf="formSubmited" fxLayout="row" fxLayoutAlign="center center">
    <h3 *ngIf="downloadStatus == 'ERROR'" style="text-align: center; font-size: 17px; color: red">Downloading Failed, Download file have error...</h3>
    <h3 *ngIf="downloadStatus == 'IN_PROGRESS'" class="txt-color-green" style="text-align: center; font-size: 17px">
      Downloading is in progress, To Download click refresh button after few seconds...
    </h3>
  </div>
  <h3 *ngIf="formSubmited" style="margin-top: 10px; display: flex; justify-content: center; align-items: center" class="secondary-text txt-color-green h1">
    <a *ngIf="downloadStatus == 'COMPLETE'" target="_blank" mat-raised-button class="reference-button mt-16 mt-sm-0" color="accent" [href]="downloadUrl">
      <mat-icon class="mr-8" class="pdf-color">picture_as_pdf</mat-icon> Download File
    </a>
    <a *ngIf="downloadStatus == 'IN_PROGRESS'" mat-raised-button class="reference-button mt-16 mt-sm-0" color="accent" (click)="getDownloadPdfStatus()">
      <mat-icon class="mr-8">refresh</mat-icon> Refresh
    </a>
  </h3>
  <div *ngIf="!isLoading && !formSubmited" class="px-16 w-100-p main-div" style="padding-bottom: 52px !important; padding-top: 10px" fxLayout="row" fxLayoutAlign="center center">
    <div style="width: 100%; max-width: 880px">
      <form #f="ngForm" class="w-100-p">
        <div fxLayout="column">
          <div class="h-44 font-size-20 font-weight-600" fxLayout="row" fxLayoutAlign="center center" style="background-color: #00c6d8 !important; color: white">
            {{ allFormData?.beforeForm?.description || allFormData?.name }}
          </div>
          <div class="p-20 mt-4 bg-color-white">
            <!--   <div class="p-20 mt-8" fxLayout="row" fxLayoutAlign="start start" style="background-color: #F6F6F6;" -->

              <div *ngIf="allFormData.subHeader && allFormData.subHeader.description && allFormData.subHeader.description != '<p> </p>'" class="mt-8">
                <div style="font-size: 15px" [innerHTML]="allFormData.subHeader.description"></div>
              </div>

            <div class="" fxLayout="column">
              <!-- <div class="font-size-20" style="color:#005F8D">
                            Personal Info
                            </div> -->
              <div>
                <div
                  class="forms-fields mt-20"  style="margin-top: 20px;"
                  [class.last-big]="(customFields.length % 2 != 0 && i === customFields.length - 1) || formField.customField.type == 'header' || formField.customField.type == 'textarea'"
                  *ngFor="let formField of customFields; let i = index"
                >
                  <h3 class="m-0" *ngIf="formField.customField.type == 'header'">
                    {{ formField.customField.name }}
                  </h3>
                  <div
                    *ngIf="
                      formField.customField.type !== 'checkbox' &&
                      formField.customField.type !== 'radio' &&
                      formField.customField.type !== 'textarea' &&
                      formField.customField.type !== 'header' &&
                      formField.customField.type !== 'dropdown' &&
                      !formField.customField.phonenumber
                    "
                  >
                    <ng-container *ngIf="formField.customField.name.toLowerCase() == 'name'">
                      <div>
                        <div style="width: 49%; float: left">
                          <h3 class="m-0">
                            First Name
                            <sup class="mandatory-star">*</sup>
                          </h3>
                          <input
                            [class.invalid]="fieldCtrl.invalid && fieldCtrl.touched"
                            #fieldCtrl="ngModel"
                            class="mt-8 h-44 w-100-p pl-16 div-box"
                            [value]=""
                            value=""
                            [type]="formField.customField.type"
                            [(ngModel)]="firstName"
                            [ngModelOptions]="{ standalone: true }"
                            [placeholder]="'Jon'"
                            [required]="true"
                            [disabled]="type === 'preview'"
                          />
                        </div>
                        <div style="width: 50%; float: right">
                          <h3 class="m-0">
                            Last Name
                            <sup class="mandatory-star">*</sup>
                          </h3>
                          <input
                            [class.invalid]="fieldCtrl.invalid && fieldCtrl.touched"
                            #fieldCtrl="ngModel"
                            class="mt-8 h-44 w-100-p pl-16 div-box"
                            [value]="formField.customField.value"
                            value="{{ formField.customField.value }}"
                            [type]="formField.customField.type"
                            [name]="lastName"
                            [(ngModel)]="lastName"
                            [ngModelOptions]="{ standalone: true }"
                            [placeholder]="'Doe'"
                            [required]="true"
                            [disabled]="type === 'preview'"
                          />
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="formField.customField.name.toLowerCase() != 'name'">
                      <h3 class="m-0">
                        {{ formField.customField.name }}
                        <sup *ngIf="formField.customField.isMandatory" class="mandatory-star">*</sup>
                      </h3>
                      <input
                        *ngIf="formField.customField.type !== 'textarea'"
                        [class.invalid]="fieldCtrl.invalid && fieldCtrl.touched"
                        #fieldCtrl="ngModel"
                        class="mt-8 h-44 w-100-p pl-16 div-box"
                        [value]="formField.customField.value"
                        value="{{ formField.customField.value }}"
                        [type]="formField.customField.type"
                        [name]="formField.customField.name"
                        [(ngModel)]="formField.customField.value"
                        [placeholder]="formField?.customField?.placeholder ? formField?.customField?.placeholder : ''"
                        [pattern]="patternsEmail(formField.customField)"
                        [required]="formField.customField.isMandatory"
                        [disabled]="type === 'preview'"
                      />
                    </ng-container>
                  </div>
                  <div *ngIf="formField.customField.phonenumber">
                    <h3 class="m-0">
                      {{ formField.customField.name }}
                      <sup *ngIf="formField.customField.isMandatory" class="mandatory-star">*</sup>
                    </h3>
                    <input
                      type="hidden"
                      class="mt-8 h-44 pl-16 div-box-guardians ml-8"
                      #fieldCtrl="ngModel"
                      ngModel
                      [name]="formField.customField.name"
                      [required]="formField.customField.isMandatory"
                    />
                    <app-phone-number (getPhoneNumber)="updatePhoneNumber($event, f, formField.customField.name)" [phone]="phone" [heading]="false" [disabled]="type === 'preview'"></app-phone-number>
                  </div>
                  <div *ngIf="formField.customField.type === 'textarea'">
                    <h3 class="m-0">
                      {{ formField.customField.name }}
                      <sup *ngIf="formField.customField.isMandatory" class="mandatory-star">*</sup>
                    </h3>
                    <textarea
                      [class.invalid]="fieldCtrl.invalid && fieldCtrl.touched"
                      class="mt-8 h-44 w-100-p pl-16 div-box"
                      #fieldCtrl="ngModel"
                      maxlength="255"
                      ngModel
                      [name]="formField.customField.name"
                      [placeholder]="formField?.customField?.placeholder"
                      [pattern]="patterns(formField.customField.type)"
                      [required]="formField.customField.isMandatory"
                      [disabled]="type === 'preview'"
                    >
                    </textarea>
                  </div>
                  <div *ngIf="formField.customField.type === 'checkbox'" fxLayout="row" fxLayoutAlign="start top">
                    <mat-checkbox [required]="formField?.customField?.isMandatory" class="example-margin font-weight-600 mx-12" style="color: #505050" [name]="formField.customField.name" #fieldCtrl="ngModel" ngModel [disabled]="type === 'preview'">
                    </mat-checkbox>
                     <h3 class="m-0 font-weight-600">{{ formField.customField.name }}</h3> 
                  </div>
                  
                  <div  *ngIf="formField.customField.type === 'dropdown'" >
                    <div fxLayout="row" fxLayoutAlign="start" fxLayout="column" fxFlex="1 1 auto">
                      <h3 class="m-0">
                        {{ formField.customField.name }}
                        <sup *ngIf="formField.customField.isMandatory" class="mandatory-star">*</sup>
                      </h3>
                      <mat-form-field appearance="outline" fxFlex="100" matNativeControl style="width: 100%;">
                        <mat-label>{{ formField.customField.name }}</mat-label>
                        <mat-select #fieldCtrl="ngModel" ngModel [name]="formField.customField.name" [disabled]="type === 'preview'">
                          <mat-option *ngIf="formField.customField.option1" [value]="formField.customField.option1">{{ formField.customField.option1 }}</mat-option>
                          <mat-option *ngIf="formField.customField.option2" [value]="formField.customField.option2">{{ formField.customField.option2 }}</mat-option>
                          <mat-option *ngIf="formField.customField.option3" [value]="formField.customField.option3">{{ formField.customField.option3 }}</mat-option>
                          <mat-option *ngIf="formField.customField.option4" [value]="formField.customField.option4">{{ formField.customField.option4 }}</mat-option>
                          <mat-option *ngIf="formField.customField.option5" [value]="formField.customField.option5">{{ formField.customField.option5 }}</mat-option>
                          <mat-option *ngIf="formField.customField.option6" [value]="formField.customField.option6">{{ formField.customField.option6 }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  
                  <div *ngIf="formField.customField.type === 'radio'">
                    <h3 class="m-0">
                      {{ formField.customField.name }}
                      <sup *ngIf="formField.customField.isMandatory" class="mandatory-star">*</sup>
                    </h3>
                    <div class="radioData mt-8 w-100-p pl-16">
                      <mat-radio-group #fieldCtrl="ngModel" ngModel [name]="formField.customField.name" [disabled]="type === 'preview'">
                        <mat-radio-button class="ml-4" *ngIf="formField.customField.option1" value="{{ formField.customField.option1 }}">{{ formField.customField.option1 }}</mat-radio-button>
                        <mat-radio-button class="ml-4" *ngIf="formField.customField.option2" value="{{ formField.customField.option2 }}">{{ formField.customField.option2 }}</mat-radio-button>
                        <mat-radio-button class="ml-4" *ngIf="formField.customField.option3" value="{{ formField.customField.option3 }}">{{ formField.customField.option3 }}</mat-radio-button>
                        <mat-radio-button class="ml-4" *ngIf="formField.customField.option4" value="{{ formField.customField.option4 }}">{{ formField.customField.option4 }}</mat-radio-button>
                        <mat-radio-button class="ml-4" *ngIf="formField.customField.option5" value="{{ formField.customField.option5 }}">{{ formField.customField.option5 }}</mat-radio-button>
                        <mat-radio-button class="ml-4" *ngIf="formField.customField.option6" value="{{ formField.customField.option6 }}">{{ formField.customField.option6 }}</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="allFormData.isShowEmergencyContact || allFormData.isShowBirthdate" class="" fxLayout="column">
              <div class="font-size-20" style="color: #005f8d; padding-top: 10px">Other Info</div>

              <div *ngIf="allFormData.isShowEmergencyContact">
                <div class="forms-fields mt-20">
                  <h3 class="m-0">Emergency Contact Name</h3>
                  <input
                    name="emergencyContactName"
                    #emergencyNameCtrl="ngModel"
                    ngModel
                    class="mt-8 h-44 w-100-p pl-16 div-box"
                    type="text"
                    [pattern]="patterns('text')"
                    [class.invalid]="emergencyNameCtrl.invalid && emergencyNameCtrl.touched"
                    [disabled]="type === 'preview'"
                    placeholder="Emergency Contact Name"
                  />
                </div>
                <div class="forms-fields mt-20">
                  <h3 class="m-0">Emergency Contact Number</h3>
                  <input type="hidden" name="emergencyContactPhone" #emergencyNumberCtrl="ngModel" ngModel />
                  <app-phone-number (getPhoneNumber)="updatePhoneNumber($event, f, 'emergencyContactPhone')" [phone]="phone" [heading]="false" [disabled]="type === 'preview'"></app-phone-number>
                </div>
              </div>
              <div *ngIf="allFormData.isShowBirthdate">
                <div class="forms-fields mt-20 w-100-p">
                  <h3 class="m-0">Date of Birth</h3>
                  <input class="mt-8 h-44 w-100-p pl-16 div-box" #DOBCtrl="ngModel" name="dob" ngModel type="date" placeholder="MM/DD/YY" [disabled]="type === 'preview'" />
                </div>
              </div>
            </div>

            <div *ngIf="allFormData.isShowGuardian" class="" fxLayout="column">
              <div class="font-size-20" style="color: #005f8d">Guardians' Info</div>
              <div>
                <div class="address mt-20">
                  <h3 class="m-0">Mother</h3>
                  <div fxLayout="column">
                    <div fxLayout="row">
                      <input
                        fxFlex="50"
                        class="mt-8 h-44 pl-16 div-box-guardians"
                        name="motherName"
                        #motherNameCtrl="ngModel"
                        ngModel
                        type="text"
                        [pattern]="patterns('text')"
                        [class.invalid]="motherNameCtrl.invalid && motherNameCtrl.touched"
                        [disabled]="type === 'preview'"
                        placeholder="Mother's Name"
                      />
                      <input
                        fxFlex="50"
                        class="mt-8 h-44 pl-16 div-box-guardians ml-8"
                        name="motherEmail"
                        #motherEmailCtrl="ngModel"
                        ngModel
                        type="email"
                        [pattern]="patterns('email')"
                        [class.invalid]="motherEmailCtrl.invalid && motherEmailCtrl.touched"
                        [disabled]="type === 'preview'"
                        placeholder="Email"
                      />
                    </div>
                    <input type="hidden" class="mt-8 h-44 pl-16 div-box-guardians ml-8" name="motherPhone" #motherNumberCtrl="ngModel" ngModel />
                    <app-phone-number
                      (getPhoneNumber)="updatePhoneNumber($event, f, 'motherPhone')"
                      [phone]="phone"
                      [heading]="false"
                      [disabled]="type === 'preview'"
                      [class.invalid]="motherNumberCtrl.invalid && motherNumberCtrl.touched"
                    ></app-phone-number>
                  </div>
                </div>
              </div>
              <div>
                <div class="address mt-20">
                  <h3 class="m-0">Father</h3>
                  <div fxLayout="column">
                    <div fxLayout="row">
                      <input
                        fxFlex="50"
                        class="mt-8 h-44 pl-16 div-box-guardians"
                        name="fatherName"
                        #fatherNameCtrl="ngModel"
                        ngModel
                        [class.invalid]="fatherNameCtrl.invalid && fatherNameCtrl.touched"
                        [pattern]="patterns('text')"
                        type="text"
                        placeholder="Name"
                        [disabled]="type === 'preview'"
                      />
                      <input
                        fxFlex="50"
                        class="mt-8 h-44 pl-16 div-box-guardians ml-8"
                        name="fatherEmail"
                        [pattern]="patterns('email')"
                        #fatherEmailCtrl="ngModel"
                        ngModel
                        type="email"
                        placeholder="Email"
                        [disabled]="type === 'preview'"
                        [class.invalid]="fatherEmailCtrl.invalid && fatherEmailCtrl.touched"
                      />
                    </div>
                    <input type="hidden" class="mt-8 h-44 pl-16 div-box-guardians ml-8" name="fatherPhone" #fatherNumberCtrl="ngModel" ngModel />
                    <app-phone-number (getPhoneNumber)="updatePhoneNumber($event, f, 'fatherPhone')" [phone]="phone" [heading]="false" [disabled]="type === 'preview'"></app-phone-number>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div *ngIf="isDemoCourseFlow" class="" fxLayout="column">
										<mat-form-field appearance="outline" fxFlex="100" matNativeControl style="width: 100%;padding-top: 20px;">
											<mat-label style="font-weight: bold">Grade of Child</mat-label>
											<mat-select [disabled]="type === 'preview'" name="course"  name="course"  [(ngModel)]="course">
												<mat-option *ngFor="let option of courseList" [value]="option.guId">
												{{ option.name | titlecase }}
												</mat-option>
											</mat-select>
										</mat-form-field>
                </div> -->
            <!--  <div class="p-20 mt-8" style="background-color: #F6F6F6;">   -->

            <h3 *ngIf="allFormData.isSignRequired" class="m-0" style="margin-bottom: 7px !important; margin-top: 7px !important">Signature<sup class="mandatory-star">*</sup></h3>
            <div *ngIf="allFormData.isSignRequired" class="signature-container">
              <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
            </div>

            <div *ngIf="allFormData.isSignRequired">
              <h3 *ngIf="isSignRequired" style="color: red; margin-top: -10px; margin-bottom: 20px">Signature is required!</h3>
            </div>

            <div *ngIf="allFormData.isSignRequired" class="bg-color-white" style="margin-top: -13px !important; margin-bottom: 2px; padding: 10px !important; padding-left: 0px !important">
              <h3 class="m-0"><span>Date:</span> {{ currDate * 1000 | date : 'MMM dd, YYYY' : 'UTC' }}</h3>
            </div>

            <div *ngIf="allFormData.afterForm && allFormData.afterForm.description && allFormData.afterForm.description != '<p> </p>'" class="" style="margin-bottom: 40px; margin-top:40px">
              <div style="font-size: 17px" [innerHTML]="allFormData.afterForm.description"></div>
            </div>
            <!-- <div class="signature-image" *ngIf="signatureImg">
                                        <img src='{{ signatureImg }}' />
                                      </div> -->
            <router-outlet></router-outlet>

            <!-- <div>
                
                    <h3 class="pl-4 m-0 mt-28 font-weight-900">Click here to accept terms</h3>
                </div>
                <div class="example-section mt-4 p-8 border-box bg-color-white">
                    <mat-checkbox name="isWaiverFormToBeSigned" required #tncCtrl="ngModel" ngModel
                        class="example-margin font-weight-500" style="color:#505050" [disabled]="type === 'preview'">
                        I accept the <a class="terms-link" href="">Terms of Use</a> and <a class="terms-link"
                            href="">Privacy
                            policy</a></mat-checkbox>
                </div> -->
            <!-- <button [disabled]="f.invalid || type === 'preview'" [class.disabled]="f.invalid || type === 'preview'"
                    class="mt-48 bg-color-white h-36 w-100-p font-size-18 register-butn cursor-pointer" fxLayout="row" required
                    fxLayoutAlign="center center" (click)="submitForm(f)">
                    Submit
                </button> -->

            <div fxLayout="row" fxLayoutAlign="end center" style="margin-top: 15px">
              <button mat-raised-button color="accent" class="submit-button" aria-label="Submit" (click)="clearSignature()" *ngIf="allFormData.isSignRequired" style="margin-right: 10px">
                Clear Signature Pad
              </button>
              <button
                [disabled]="f.invalid || type === 'preview'"
                [class.disabled]="f.invalid || type === 'preview'"
                mat-raised-button
                color="accent"
                class="submit-button"
                aria-label="Submit"
                required
                (click)="verifyRecaptcha(f)"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
