import { FuseNavigation } from '@fuse/types';

export const admin_navigation: FuseNavigation[] = [
  {
    id: 'dashboardMenu',
    title: 'Overview',
    type: 'group',
    children: [
      {
        id: 'getstarted',
        title: 'Get Started',
        type: 'item',
        icon: 'edit icon',
        url: '/getstarted',
      },
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'dashboard',
        url: '/dashboard',
      },
    ],
  },
  {
    id: 'applications',
    title: 'Launch',
    // translate: "NAV.ADMIN",
    type: 'group',
    children: [
      {
        id: 'website',
        title: 'Website',
        type: 'item',
        icon: 'language',
        url: '/website',
      },
      {
        id: 'schedule',
        title: 'Classes',
        // translate: "NAV.SCHEDULE.TITLE",
        type: 'item',
        icon: 'calendar_today',
        url: '/schedule',
      },
      {
        id: 'rules',
        title: 'Events',
        // translate: "NAV.SCHEDULE.TITLE",
        type: 'item',
        icon: 'class',
        url: '/rules',
      },
      {
        id: 'productsGroup',
        title: 'Products',
        type: 'collapsable',
        icon: 'dashboard_icon',
        children: [
          {
            id: 'services',
            title: 'Courses',
            // translate: "NAV.COURSES.TITLE",
            type: 'item',
            icon: 'category',
            url: '/services',
            /*badge: {
          title: "25",
          translate: "NAV.COURSES.BADGE",
          bg: "#F44336",
          fg: "#FFFFFF",
        },*/
          },
          // {
          //   id: "facility",
          //   title: "Facility",
          //   type: "item",
          //   icon: "store",
          //   url: "/facility"
          // },
          {
            id: 'bundles',
            title: 'Bundles',
            type: 'item',
            icon: 'style',
            url: '/product-bundle',
            isBeta: true,
          },
          {
            id: 'donation',
            title: 'Donations',
            // translate: "NAV.SCHEDULE.TITLE",
            type: 'item',
            icon: 'volunteer_activism',
            url: '/donation',
          },
          {
            id: 'products',
            title: 'Merchandise',
            type: 'item',
            icon: 'shop_two',
            url: '/products',
          },
        ],
      },
      {
        id: 'engage',
        title: 'Engage',
        // translate: "NAV.ADMIN",
        type: 'group',
        children: [
          {
            id: 'contacts',
            title: 'Contacts',
            // translate: "NAV.CONTACT.TITLE",
            type: 'item',
            icon: 'group',
            url: '/contacts',
          },
          {
            id: 'campaign',
            title: 'Campaigns',
            // translate: "NAV.SCHEDULE.TITLE",
            type: 'item',
            icon: 'email',
            url: '/campaign',
          },
        ],
      },
      {
        id: 'ManageMenus',
        title: 'Manage',
        type: 'group',
        children: [
          {
            id: 'tests',
            title: 'Assessments',
            // translate: "NAV.SCHEDULE.TITLE",
            type: 'item',
            icon: 'library_books',
            url: '/tests',
          },
          {
            id: 'selfAssessment',
            title: 'Self Assessments',
            // translate: "NAV.SCHEDULE.TITLE",
            type: 'item',
            icon: 'library_books',
            url: '/assesment',
            hidden: true ,
          },
          {
            id: 'form',
            title: 'Forms',
            // translate: "NAV.SCHEDULE.TITLE",
            type: 'item',
            icon: 'assignment',
            url: '/customforms',
          },
          {
            id: 'questionBank',
            title: 'Question Banks',
            // translate: "NAV.SCHEDULE.TITLE",
            type: 'item',
            icon: 'folder_special',
            url: '/qbank',
          },
          {
            id: 'store',
            title: 'Store',
            type: 'item',
            icon: 'add_shopping_cart',
            url: '/store',
          },
          {
            id: 'invoices',
            title: 'Invoices',
            type: 'item',
            icon: 'receipt',
            url: '/invoices',
          },
        ],
      },
      {
        id: 'tenantplan',
        title: 'MasterPlans',
        //translate: "NAV.SCHEDULE.TITLE",
        type: 'item',
        icon: 'account_balance_wallet',
        url: '/masterplan',
      },
      {
        id: 'tenant',
        title: 'Tenants',
        //translate: "NAV.SCHEDULE.TITLE",
        type: 'item',
        icon: 'domain',
        url: '/tenant',
      },
    ],
  },
  {
    id: 'profileMenu',
    title: 'Resources',
    type: 'group',
    children: [
      {
        id: 'Reports',
        title: 'Reports',
        type: 'item',
        icon: 'insert_chart_outlined',
        url: '/reports',
      },
      {
        id: 'settings',
        title: 'Settings',
        type: 'item',
        icon: 'settings',
        url: '/settings',
      },
      {
        id: 'help',
        title: 'Help',
        type: 'item',
        icon: 'help_outline',
        url: 'https://support.wajooba.com/',
        externalUrl: true,
        openInNewTab: true,
      },
      // {
      //   id: 'plans',
      //   title: 'Plans',
      //   type: 'item',
      //   icon: 'settings',
      //   url: '/jpt',
      // },
      {
        id: 'logout',
        title: 'Logout',
        type: 'item',
        icon: 'exit_to_app',
        url: '/logout',
      },
    ],
  },
];

