import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer, Location } from '@angular/common';

import { Platform } from '@angular/cdk/platform';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
// import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { TenantUserService } from './core/services/tenantuser.service';
import { TenantService } from './core/services/tenant.service';
import { TUser } from './core/models/tenantuser';
import { admin_navigation } from './navigation/admin.navigation';
import { student_navigation } from './navigation/student.navigation';
import { AppConstants } from './core/settings/appconstants';
import { AppConfigService } from './core/services/appconfig.service';
import { AppUtilsService } from './core/services/apputils.service';
import { Tenant } from './core/models/tenant';
import { SubSink } from 'subsink';
import { Title, Meta } from '@angular/platform-browser';
import { AppLoadService } from './core/services/appload.service';
import { CustomFormService } from 'app/main/shared/components/custom-form/custom-form.service';
import { AppSettingsService } from './main/admin/app-settings/services/app-settings.service';
import { TenantSettingMenuService } from './components/tenantsettingmenu.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { TenantPing } from './core/models/tenantPing';
import Quill from 'quill';
import { CheatSystemService } from './main/shared/services/cheat-system.service';
@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  cheatStatus: 'standby' | 'executed' | 'failed' | null; // Default is 'standby'
  fuseConfig: any;
  navigation: any;
  user: TUser;
  tenant: Tenant;
  isUserTriggerConfigChange = false;
  // Private
  subs = new SubSink();
  onboard: boolean;
  favIcon: HTMLLinkElement;
  isLoading: boolean = true;
  // private _configSubject: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {FuseSplashScreenService} _fuseSplashScreenService
   * @param {Platform} _platform
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    // @Inject(PLATFORM_ID) public platformId,
    private _fuseConfigService: FuseConfigService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _fuseNavigationService: FuseNavigationService,
    private _tenantSettingMenuService: TenantSettingMenuService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _platform: Platform,
    private _tenantUserService: TenantUserService,
    private _tenantService: TenantService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _location: Location,
    private metaTagService: Meta,
    private titleService: Title,
    private _customFormService: CustomFormService,
    private _appLoadService: AppLoadService,
    private _appSettingsService: AppSettingsService, // private document: Document
    private _cheatSystemService: CheatSystemService,
  ) {
    this._cheatSystemService.commandStatus$.subscribe((value)=>{
      this.cheatStatus = value;
    });
    //console.log(new Date() + "app component constructor started")
    this.fuseConfig = AppConfigService.getPublicConfig();
    // this.isBrowser = isPlatformBrowser(platformId);
    // this.isServer = isPlatformServer(platformId);

    // Get default navigation
    this.navigation = navigation;

    // Register the navigation to the service
    this._fuseNavigationService.register(AppConstants.PUBLIC_NAVIGATION, this.navigation);
    this._fuseNavigationService.register(AppConstants.ADMIN_NAVIGATION, admin_navigation);
    this._fuseNavigationService.register(AppConstants.STUDENT_NAVIGATION, student_navigation);

    // Add languages
    // this._translateService.addLangs(["en", "tr"]);

    // Set the default language
    // this._translateService.setDefaultLang("en");

    // Set the navigation translations
    // this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

    // Use a language
    // this._translateService.use("en");

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ----------------------------------------------------------------------------------------------------
     */

    /**
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     */

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.
    /**
     * setTimeout(() => {
     * this._translateService.setDefaultLang('en');
     * this._translateService.setDefaultLang('tr');
     * });
     */

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix End
     * ----------------------------------------------------------------------------------------------------
     */

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // Quill Editor fix
    const parchment = Quill.import('parchment');
    const block = parchment.query('block');
    block.tagName = 'DIV';
    Quill.register(block, true);
    
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  @HostListener('window:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    const key = event.key;
    this._cheatSystemService.handleKeyPress(key);
  }

  ngOnInit(): void {
    // Set the applicaiton layout and navigation when user changes
    this._fuseProgressBarService.setMode('determinate');
    this._fuseProgressBarService.setBufferValue(50);
    this._fuseProgressBarService.show();
    this._fuseProgressBarService.setValue(50);
    this.subs.add(
      this._tenantUserService.user.subscribe((user: TUser) => {
        //console.log("app.component -> initialize user");
        this.user = user;
        this.isUserTriggerConfigChange = true;
        // this.isUserTriggerConfigChange = this.user.isLoggedIn ? true : false;
        this._tenantSettingMenuService.updateTenantSettingMenus(this.user);
      }),
    );

    this.subs.add(
      this._tenantSettingMenuService.onTenantSettingChanged.subscribe(data => {
        if (data === AppConstants.INITIAL_EVENT) {
          console.log('ignoring initial tenantsetting change');
          return;
        }
        if (data.isCustomizeCss) {
          this.loadStyle(this.user.orgId + '.css');
        }

        this.refreshAndInitNavigation();
      }),
    );

    // On navigation changed
    this.subs.add(
      this._fuseNavigationService.onNavigationChanged.subscribe(key => {
        //console.log("appComponent - onNavigationChanged called")
        if (key === AppConstants.INITIAL_EVENT) {
          console.log('ignoring initial navigation change');
          return;
        }

        this.updateSeoChanges(key);
        this._fuseConfigService.setConfig(AppConfigService.getLayoutConfig(this.user));
      }),
    );

    // Subscribe to config changes
    this.subs.add(
      this._fuseConfigService.config.subscribe(config => {
        if (config == null) {
          console.log('ignoring fuseConfigService initial event with value ' + config);
          return;
        }
        this.fuseConfig = config;
        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        } else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
        if (this.isUserTriggerConfigChange) {
          this.isUserTriggerConfigChange = false;
          //console.log(new Date() + "-isUserTrigger Config Chnage");
          this.navigateToScreen();
        }
      }),
    );
  }

  private async refreshAndInitNavigation(): Promise<void> {
    const isValPresent = localStorage.getItem(AppConstants.WEBSITE_REFRESHED);
    if (isValPresent) {
      localStorage.removeItem(AppConstants.WEBSITE_REFRESHED);
      await this._appLoadService.reping();
    }
    this._tenantSettingMenuService.initNavigationMenus(this.user, this._router);
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this._tenantUserService.getuser().isPreviewMode) {
      this._location.replaceState('/dashboard');
      this._tenantUserService.setPreviewMode(false);
    }
  }

  private getTempNavigation(navKey: string) {
    let tempKey = null;
    if (navKey == AppConstants.PUBLIC_NAVIGATION) {
      tempKey = AppConstants.ADMIN_NAVIGATION;
    } else if (navKey == AppConstants.ADMIN_NAVIGATION) {
      tempKey = AppConstants.PUBLIC_NAVIGATION;
    } else if (navKey == AppConstants.STUDENT_NAVIGATION) {
      tempKey = AppConstants.ADMIN_NAVIGATION;
    }
    return tempKey;
  }

  updateSeoChanges(key: string) {
    const tenantConfig = this._appLoadService.tenantConfig;
    const org = tenantConfig.org;

    //Inititalize Defaults
    const title = org.title ? org.title : AppConstants.PAGE_DEFAULT_TITLE;
    const description = org.seoDescription ? org.seoDescription : AppConstants.PAGE_DEFAULT_DESCRIPTION;
    const logo = org.logo ? AppUtilsService.buildCloudinaryImageUrl(tenantConfig.cloudName, org.logo, org.logoWidth, org.logoHeight, true) : "https://www.wajooba.com/wp-content/uploads/2023/04/wajooba_logo_high_res.png";

    this.favIcon = document.querySelector('#appIcon');
    if (this.favIcon && org.favIcon !== undefined && org.favIcon !== null && org.favIcon !== '') {
      this.favIcon.href = AppUtilsService.buildCloudinaryImageUrl(tenantConfig.cloudName, org.favIcon, 16, 16, false);
    }
    
    this.titleService.setTitle(title);
    this.metaTagService.updateTag({ name: AppConstants.PAGE_DESCRIPTION, content: description });
    if (key === AppConstants.PUBLIC_NAVIGATION) {
      this.metaTagService.updateTag({ name: AppConstants.PAGE_ROBOTS, content: 'index, follow' });
      this.metaTagService.updateTag({ name: AppConstants.PAGE_OG_TITLE, content: title });
      this.metaTagService.updateTag({ name: AppConstants.PAGE_OG_DESCRIPTION, content: description });
      this.metaTagService.updateTag({ name: AppConstants.PAGE_OG_IMAGE, content: logo });
      // this.metaTagService.updateTag({ property: AppConstants.PAGE_OG_TITLE, content: title });
      // this.metaTagService.updateTag({ property: AppConstants.PAGE_OG_DESCRIPTION, content: description });
      // this.metaTagService.updateTag({ property: AppConstants.PAGE_OG_IMAGE, content: AppUtilsService.buildCloudinarImageUrl(tenantConfig.cloudName,  org.logo, org.logoWidth, org.logoHeight, true) });
      this.metaTagService.updateTag({ name: AppConstants.PAGE_TWITTER_TITLE, content: title });
      this.metaTagService.updateTag({ name: AppConstants.PAGE_TWITTER_DESCRIPTION, content: description });
      this.metaTagService.updateTag({ name: AppConstants.PAGE_TWITTER_IMAGE, content: logo });
    }
  }

  private navigateToScreen(): void {
    this.isLoading = false;
    if (this._tenantUserService.isZoomLogin()) {
      this.navigateToZoomContext();
    } else if (this._tenantUserService.isInstamojo()) {
      this.navigateToInvoiceScreen();
    } else if (this._tenantUserService.isStripeScreen() && !this.user.isLoggedIn) {
      this.navigateToLoginScreen();
    } else if (this._tenantUserService.isStripeScreen()) {
      this.navigateToStripeSettingScreen();
    } else if (this._tenantUserService.isZoomScreen() && !this.user.isLoggedIn) {
      this.navigateToLoginScreen();
    } else if (this._tenantUserService.isZoomScreen()) {
      this.navigateToZoomSettingScreen();
    } else if (!this.user.isLoggedIn) {
      this.navigateToPublicScreen();
    } else {
      this.navigateToInitialRoute();
    }
  }

  private navigateToZoomSettingScreen() {
    this._tenantUserService.removeZoomKey();
    this._router.navigate(['/integrations/zoom']);
  }

  private navigateToStripeSettingScreen() {
    this._tenantUserService.removeStripeKey();
    this._router.navigate(['/integrations/paymentprovider']);
  }

  private navigateToLoginScreen() {
    this._router.navigate(['/login']);
  }

  private navigateSignUpScreen(): void {
    const toParams = JSON.parse(this._tenantUserService.getEventParam());
    this._tenantUserService.removeEventParam();
    this._tenantUserService.removeEventType();
    if (toParams === null || toParams === undefined) {
      this.navigateOnUserRole();
    }
    const classId = toParams.classId;
    const clsStartTime = toParams.clsStartTime;
    if (toParams.scheduleId != null) {
      this._router.navigate([`/signup/${classId}/${clsStartTime}`], {
        relativeTo: this._activatedRoute,
        queryParams: { scheduleId: toParams.scheduleId },
      });
    } else {
      this._router.navigate([`/signup/${classId}/${clsStartTime}`], {
        relativeTo: this._activatedRoute,
      });
    }
  }
  // TODO
  private navigateToInitialRoute(): void {
    const isStudentRole = TenantUserService.isStudent(this.user);
    const isStaff = this._tenantUserService.isStaffRole();
    let tenantObj = this._tenantService.getTenant();
    const isEmailVerified = this.user.isEmailVerified;
    const isStudentPreview = this._tenantUserService.isStudentPreview();
    // if (this.user.isFirstLogin && !isStudentPreview) {
    //   this._router.navigateByUrl(AppConstants.SL_ONBOARDING_ROUTE);
    // } else
    // if (isStudentRole && !isEmailVerified && !isStudentPreview) {
    //   this._router.navigateByUrl(AppConstants.SL_ONBOARDING_ROUTE);
    // } else
    if (tenantObj && tenantObj.isNewTenant && isStaff) {
      this._router.navigateByUrl(AppConstants.SL_ONBOARDING_ROUTE);
    }
    // else if (!this.user.hasAcceptedTerms && !isStudentPreview) {
    //   this._router.navigate([AppConstants.SL_ONBOARDING_ROUTE]);
    // }
    else if (isStudentRole && this._tenantUserService.getEventParam()) {
      this.navigateSignUpScreen();
    } else {
      this.navigateOnUserRole();
    }
  }

  private navigateToZoomContext(): void {
    if (!this.user.isLoggedIn) {
      this._router.navigate(['/login']);
    } else {
      this._tenantUserService.clearZoomToken();
    }
  }

  private navigateToPublicScreen(): void {
    const validRoute = this._location.path();
    const tenantConfig = this._appLoadService.getTenantPing();
    const orgId = this._appLoadService.getOrgId();
    const isIntHomePresent = this._tenantUserService.isHomePagePresent(tenantConfig.web);
    const isEventPresent = this._tenantUserService.isEventsPagePresent(tenantConfig.web);

    var finalRoute: string = "";

    if (!tenantConfig || !tenantConfig.orgId) {
      finalRoute = AppConstants.SL_LOGIN;
    } else if (validRoute.startsWith(AppConstants.SL_TPLAN_ROUTE) && orgId !== AppConstants.MASTER_TENANT) {
      finalRoute = AppConstants.PAGE_NOT_FOUND;
    } else if (isIntHomePresent && (validRoute == AppConstants.SL_NO_ROUTE || validRoute == AppConstants.SL_LOGOUT_ROUTE)) {
      finalRoute = AppConstants.SL_NO_ROUTE;
    } else if (validRoute == AppConstants.SL_CALENDAR_ROUTE) {
      finalRoute = tenantConfig.isShowSchedule ? AppConstants.SL_CALENDAR_ROUTE : AppConstants.PAGE_NOT_FOUND;
    } else if (validRoute.startsWith(AppConstants.SL_COURSES_ROUTE)) {
      finalRoute = tenantConfig.isShowCourses ? validRoute : AppConstants.PAGE_NOT_FOUND;
    } else if (validRoute == AppConstants.SL_PUBLIC_REGISTRATION_FORM) {
      finalRoute = tenantConfig.isShowRegistrationLink ? this.getFirstFormRoute(tenantConfig, isIntHomePresent, isEventPresent) : AppConstants.PAGE_NOT_FOUND;
    } else if (validRoute.startsWith(AppConstants.SL_PUBLIC_REGISTRATION_FORM)) {
      finalRoute = tenantConfig.isShowRegistrationLink ? validRoute : AppConstants.PAGE_NOT_FOUND;
    } else if (validRoute == AppConstants.SL_LOGOUT_ROUTE) {
      finalRoute = this.calcLogoutRoute(tenantConfig);
    } else if (validRoute == AppConstants.SL_NO_ROUTE) {
      finalRoute = this.getDefaultPublicRoute(tenantConfig, isIntHomePresent, isEventPresent);
    } else {
      // const isValidPublicRoute = AppUtilsService.isValidPublicRoute(validRoute);
      const isValidPublicRoute = this._appLoadService.validatePublicUrlsInHostName();
      finalRoute = isValidPublicRoute ? validRoute : this.getDefaultPublicRoute(tenantConfig, isIntHomePresent, isEventPresent);
    } 
    const parsedUrl = this._router.parseUrl(finalRoute);
    this._router.navigate([parsedUrl.root.children['primary']?.segments?.map(segment => segment.path).join('/') || ''],{queryParams:parsedUrl.queryParams,relativeTo: this._activatedRoute});
  }

  private calcLogoutRoute(tenantObject: TenantPing) {
    let logoutRoute;
    if (tenantObject.isShowSchedule) {
      logoutRoute = AppConstants.SL_CALENDAR_ROUTE;
    } else if (tenantObject.isShowCourses) {
      logoutRoute = AppConstants.SL_COURSES_ROUTE;
    } else {
      logoutRoute = AppConstants.SL_LOGIN_ROUTE;
    }
    return logoutRoute;
  }

  private getFirstFormRoute(tenantConfig: TenantPing, isIntHomePresent: boolean, isEventsPresent: boolean): string {
    const formsList = tenantConfig.forms;
    let route;
    if (formsList && formsList.length > 0) {
      route = AppConstants.SL_PUBLIC_REGISTRATION_FORM + '/' + formsList[0].guId;
    } else {
      route = this.getDefaultPublicRoute(tenantConfig, isIntHomePresent, isEventsPresent);
    }
    return route;
  }

  private findValidPublicRoute(curRoute: string, tenantConfig: TenantPing, isIntHomePresent: boolean): string {
    let validRoute = curRoute;
    if (AppUtilsService.isValidAdminRoute(curRoute) || AppUtilsService.isValidStudentRoute(curRoute)) {
      validRoute = AppConstants.PAGE_NOT_FOUND;
    }
    return validRoute;
  }

  private getDefaultPublicRoute(tenantObject: TenantPing, isIntHomePresent: boolean, isEventsPresent: boolean): string {
    const webPageList = this._tenantSettingMenuService.getSortedPageData(tenantObject.web);

    let defaultRoute = AppConstants.SL_LOGIN;
    if (isIntHomePresent) {
      defaultRoute = AppConstants.SL_NO_ROUTE;
    } else {
      const firstPage = webPageList.find(page => page.externalUrl !== '' && page.name !== 'home');
      if (firstPage == undefined) {
        defaultRoute = AppConstants.SL_LOGIN_ROUTE
      } else {
        defaultRoute = firstPage.url;
      }
    }

    // else if (tenantObject.isShowSchedule) {
    //   defaultRoute = AppConstants.SL_CALENDAR_ROUTE;
    // } else if (tenantObject.isShowCourses) {
    //   defaultRoute = AppConstants.SL_COURSES_ROUTE;
    // } else if (isEventsPresent) {
    //   defaultRoute = AppConstants.SL_EVENTS_ROUTE;
    // } else if (tenantObject.isShowRegistrationLink) {
    //   defaultRoute = AppConstants.PUBLIC_REGISTRATION_FORM_ROUTE;
    // }
    return defaultRoute;
  }

  private navigateToInvoiceScreen(): void {
    this._tenantUserService.removeInstamojoType();
    this._router.navigate(['/checkout/invoice/create']);
  }

  private navigateOnUserRole(): void {
    if (this._tenantUserService.isStaffRole()) {
      this.navigateToAdminScreen();
    } else {
      this.navigateToStudentScreen();
    }
  }

  private navigateToStudentScreen(): void {
    if (this.user.isPreviewMode) {
      this._router.navigate([AppConstants.SL_CALENDAR_ROUTE]);
    } else {
      const validRoute = AppUtilsService.getValidStudentRoute(this._location.path());
      const tenantObject = this._appLoadService.getTenantPing();
      if (tenantObject && tenantObject.isShowScheduleMenu == false) {
        this._router.navigateByUrl(AppConstants.SL_MYCOURSES_ROUTE);
      } else {
        this._router.navigateByUrl(validRoute);
      }
    }
  }

  private navigateToAdminScreen(): void {
    const curPath = this._location.path();
    if (this.user.isPreviewMode && this.user.isPreviewOfSecuredPage) {
      const validRoute = AppUtilsService.getValidAdminRoute(curPath);
      this._router.navigateByUrl(validRoute);
    } else if (this.user.isPreviewMode) {
      // this._router.navigate([AppConstants.SL_CALENDAR_ROUTE]);
      this.navigateToPublicScreen();
    } else {
      const validRoute = AppUtilsService.getValidAdminRoute(curPath);
      this._router.navigateByUrl(validRoute);
    }
  }

  private loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById('client-theme') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subs.unsubscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

}
