<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0 accent">
        <mat-toolbar-row fxLayoutAlign="space-between center" style=" display: flex; justify-content: space-between; ">
            <span class="title dialog-title"> Website Settings</span>
            <button mat-dialog-close mat-icon-button aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-dialog-content
            class="p-24 pb-0 m-0"
            style="margin-top: 25px;height: 100px; padding-top: 0px !important;display: flex; flex-direction: column">
            <mat-slide-toggle
                    class="external-toggle" style="padding-left: 10px;margin-right: 10px;margin-top: 10px;"
                    [(ngModel)]="isShowNavigation"
                    (change)="addUpdateInternalWebsite()"
                    labelPosition="after">
                <span style="font-size: 15px" *ngIf="isShowNavigation">Show page link on Navigation bar</span><span
                    style="font-size: 15px" *ngIf="!isShowNavigation">Hide page link from Navigation bar</span>
            </mat-slide-toggle>

            <mat-slide-toggle
                    class="external-toggle" style="padding-left: 10px;margin-right: 10px;"
                    [(ngModel)]="isShowFooter"
                    (change)="addUpdateInternalWebsite()"
                    labelPosition="after">
                <span style="font-size: 15px" *ngIf="isShowFooter">Show page link on Footer</span><span
                    style="font-size: 15px" *ngIf="!isShowFooter">Hide page link from Footer</span>
            </mat-slide-toggle>
    </mat-dialog-content>
</div>
