import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, Injectable } from "@angular/core";
import { TenantPing } from "app/core/models/tenantPing";
import { AppLoadService } from "app/core/services/appload.service";
import { FileUploader } from "ng2-file-upload";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TenantUserService } from "app/core/services/tenantuser.service";
import { AccountDetail } from "app/main/admin/app-settings/models/account-details";
import { AppSettingsService } from "app/main/admin/app-settings/services/app-settings.service";
import { SubSink } from "subsink";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"]
})
@Injectable({
  providedIn: 'root'
})
export class FileUploadComponent implements OnInit {
 
  @Input() photos: FileObject[] = [];
  @Input() orgId: string;
  @Input() prePhoto: string;
  
  @Input() videos: FileObject[] = [];
  @Input() uploadType: string = "image";
  @Input() title: string = "Add Photos";
  @Input() description: string = "";
  @Input() buttonName: string = "Upload Image";
  @Output() getFileObject = new EventEmitter<FileObject[]>();
  @Input() isFontApply: boolean = false;

  @ViewChild("videoPlayer") videoplayer: ElementRef;
  @ViewChild('fileInput') fileInput: any;

  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  _tenantConfig: AccountDetail;
  _imagePrefixPath: string;
  _baseUrl: string;
  _cloudinaryPreset: string;
  subs = new SubSink();
  private _unsubscribeAll: Subject<any>;
  

  constructor(private _appLoadService: AppLoadService, private _settingsService: AppSettingsService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.photos = [];
    this.subs.add(
      this._settingsService.getAccountDetail().subscribe(data => {
        this._tenantConfig = data;
        this._imagePrefixPath = "https://res.cloudinary.com/" + this._tenantConfig.cloudinaryCloudName;
        this._baseUrl = "https://api.cloudinary.com/v1_1/" + this._tenantConfig.cloudinaryCloudName + "/upload";
        this.initializeUploader();
      })
    );
  }

  uploadFile() {
    this.uploader.uploadAll();
    this.fileInput.nativeElement.value = '';
  }

  fileOverBase(e: any) {
    this.hasBaseDropZoneOver = e;
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

  getCloudinaryImageForLogo(imageUrl, cloudName): string {
    let list = imageUrl.split("/");
    let imageName = list[list.length - 1];

    return this.getCloudinaryImageUrl(`w_150,h_100,c_scale/${this._tenantConfig.orgId}/` + imageName, cloudName);
  }

  getCloudinaryImageUrl(imgNameWithOptions, cloudName): string {
    let cloud = this._tenantConfig.cloudinaryCloudName;
    if (cloudName) cloud = cloudName;
    return "https://res.cloudinary.com/" + cloud + "/image/upload/" + imgNameWithOptions;
  }

  initializeUploader() {
    this.uploader = new FileUploader({
      url: this._baseUrl,
      isHTML5: true,
      allowedFileType: ["image", "video"],
      removeAfterUpload: true,
      autoUpload: true,
      maxFileSize: 10 * 1024 * 1024,
      // XHR request headers
      headers: [
        {
          name: "X-Requested-With",
          value: "XMLHttpRequest"
        }
      ]
    });
    
    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      // Add Cloudinary unsigned upload preset to the upload form
      form.append("upload_preset", this._tenantConfig.cloudinaryPreset);
      // Add file to upload
      form.append("file", fileItem);
      
      form.append("folder",this._tenantConfig.orgId +'-'+ this._tenantConfig.tenantId);
      // Use default "withCredentials" value for CORS requests
      fileItem.withCredentials = false;
      return { fileItem, form };
    };

    

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      if (response) {
        const res: any = JSON.parse(response);
        const fileObject = {
          id: res.id || res.public_id,
          url: res.url
        };
        if (this.uploadType.toLowerCase() == "image") {
          this.prePhoto = '';
          // this.photos = [fileObject];
         
          this.getFileObject.emit([fileObject]);
        } else if (this.uploadType.toLowerCase() == "video") {
          this.videos = [fileObject];
          this.getFileObject.emit(this.videos);
        }
      }
    };
  }

  close() {
    this.prePhoto = null;
    try {
      const fileObject = {
        id: 1,
        url: ""
      };
      let temp = [fileObject];
      this.photos = [];
      this.videos = [];
      this.getFileObject.emit(temp);
    } catch (error) {
      console.log(error);
    }
  
  }
}

export interface FileObject {
  id: number;
  url: any;
  fileType?: string;
  name?: string;
  fileName?: string;
  s3url?: any;
  downloadUrl?: any;
  status?: string;
}
