<div style="width:100%">
  <!-- <h1> Is User Logged in {{isLoggedIn}}</h1> -->
  <!-- <h1> user orgid is {{user.orgId}}</h1> -->
  <!-- <h1> Layyout should be {{appConfig.layout}}</h1> -->
  <!-- PROGRESS BAR -->
  <fuse-progress-bar></fuse-progress-bar>
  <!-- / PROGRESS BAR -->

  <!-- VERTICAL LAYOUT 1 - FOR ADMIN & STUDENT -->
  <ng-container *ngIf="!isLoading && fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
  </ng-container>
  <!-- / VERTICAL LAYOUT 1 -->

  <!-- VERTICAL LAYOUT 2  -->
  <!-- <ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-2'">
    <vertical-layout-2></vertical-layout-2>
</ng-container> -->
  <!-- / VERTICAL LAYOUT 2 -->

  <!-- VERTICAL LAYOUT 3 -->
  <!-- <ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-3'">
    <vertical-layout-3></vertical-layout-3>
</ng-container> -->
  <!-- / VERTICAL LAYOUT 3 -->

  <!-- HORIZONTAL LAYOUT 1 FOR PUBLIC PAGE-->
  <ng-container *ngIf="!isLoading && fuseConfig.layout.style === 'horizontal-layout-1'">
    <horizontal-layout-1></horizontal-layout-1>
  </ng-container>
  <!-- / HORIZONTAL LAYOUT 1 -->

  <ng-container *ngIf="!isLoading && fuseConfig.layout.style === 'horizontal-layout-2'">
    <horizontal-layout-2></horizontal-layout-2>
  </ng-container>

  <ng-container *ngIf="!isLoading && fuseConfig.layout.style === 'blank-layout'">
    <blank-layout></blank-layout>
  </ng-container>


  <!-- THEME OPTIONS PANEL -->
  <!-- <button
    mat-icon-button
    class="warn mat-elevation-z2 theme-options-button"
    (click)="toggleSidebarOpen('themeOptionsPanel')"
  >
    <mat-icon>settings</mat-icon>
  </button> -->

  <!-- <fuse-sidebar
    name="themeOptionsPanel"
    class="theme-options-sidebar"
    position="right"
    [invisibleOverlay]="true"
  >
    <fuse-theme-options></fuse-theme-options>
  </fuse-sidebar> -->
  <!-- / THEME OPTIONS PANEL -->
</div>
<div id="cheat-status-indicator" [matTooltip]="cheatStatus" [ngClass]="{'standby': cheatStatus === 'standby', 'executed': cheatStatus === 'executed', 'failed': cheatStatus === 'failed'}"></div>
<app-onboarding *ngIf="onboard"></app-onboarding>
