<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p class="color-white">Please Wait.</p>
</ngx-spinner>

<div *ngIf="!isLoading" class="full-div" fxLayout="column" fxLayoutAlign="center center">
  <div class="resize-div">
    <div class="w-100-p card">
      <div class="w-100-p main-box" *ngIf="step === 2" fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="30" class="h-100-p inner-left-box" fxLayout="column" fxLayoutAlign="center start">
          <div class="element-center max-width-300">
            <div class="p-20 w-100-p" fxLayout="column" fxLayoutAlign="center center">
              <img src="https://passets.wajooba.com/img/Onboarding_Placeholder.png" class="w-100-p" />
            </div>
            <div class="mt-12 w-100-p" fxLayout="column" fxLayoutAlign="center center">
              <h1 class="m-0 font-size-20 font-weight-800 blue-color">
                {{ user.name }}
              </h1>
            </div>
            <div class="w-100-p my-12" fxLayout="column" fxLayoutAlign="center center">
              <h4 class="element-center">{{ user.email }}</h4>
            </div>
          </div>
        </div>
        <div fxFlex="70" class="h-100-p inner-right-box border-left" fxLayout="column" fxLayoutAlign="start start">
          <form *ngIf="!isResendVerification" #f="ngForm" class="w-100-p pl-24 pt-20 fields-form">
            <div fxLayout="row" fxLayoutAlign="center center" class="w-100-p font-weight-600 blue-color font-size-18">Profile Information</div>
            <div class="mt-12" fxLayout="column" fxLayoutAlign="start start">
              <h3 class="m-0 font-weight-600" [class.valid-name]="nameCtrl.valid" [class.invalid-name]="nameCtrl.invalid && nameCtrl.touched">
                Full Name
                <sup class="mandatory-star">*</sup>
              </h3>
              <input
                class="mt-8 h-44 w-100-p pl-16 div-box"
                name="name"
                type="text"
                [class.valid-border]="nameCtrl.valid"
                required
                [class.invalid-border]="nameCtrl.invalid && nameCtrl.touched"
                ngModel
                [pattern]="patterns('text')"
                [(ngModel)]="profileScreen.name"
                #nameCtrl="ngModel"
              />
            </div>
            <div class="mt-12" fxLayout="column" fxLayoutAlign="start start" style="width: 100%">
              <h3 class="m-0 font-weight-600">
                Phone Number
                <sup class="mandatory-star">*</sup>
              </h3>
              <app-phone-number fxFlex="100" style="width: 100%" (getPhoneNumber)="getPhoneNumber($event)" [phone]="phone" [heading]="false"> </app-phone-number>
              <h3 *ngIf="isValidPhone" class="m-0" style="padding-bottom: 5px">
                <small style="color: #f44336">{{ errorMsg }} </small>
              </h3>
              <!-- <h3 class="m-0 font-weight-600" [class.valid-name]="phoneCtrl.valid"
                                [class.invalid-name]="phoneCtrl.invalid && phoneCtrl.touched">Phone Number
                                <sup class="mandatory-star">*</sup>
                            </h3>
                            <input class="mt-8 h-44 w-100-p pl-16 div-box" name="phone" type="number"
                                [class.valid-border]="phoneCtrl.valid" required
                                [class.invalid-border]="phoneCtrl.invalid && phoneCtrl.touched" ngModel
                                [pattern]="patterns('number')" [(ngModel)]="profileScreen.phone" #phoneCtrl="ngModel"> -->
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
              <h3 class="m-0 font-weight-600" [class.invalid-name]="addressCtrl.invalid && addressCtrl.touched">Address</h3>
              <input
                class="mt-8 h-44 w-100-p pl-16 div-box"
                name="address"
                type="text"
                [class.invalid-border]="addressCtrl.invalid && addressCtrl.touched"
                ngModel
                #addressCtrl="ngModel"
                [pattern]="patterns('text')"
              />
            </div>
            <div class="mt-12">
              <mat-checkbox name="isWaiverFormToBeSigned" required ngModel class="example-margin font-weight-600 light-color">
                <h3 class="m-0 font-weight-600">
                  I have read
                  <a *ngIf="tenant.privacyPolicyLink" class="underline" target="_blank" [href]="tenant.privacyPolicyLink">Waiver form</a>
                  <span *ngIf="!tenant.privacyPolicyLink">Waiver form</span>
                </h3>
              </mat-checkbox>
            </div>
            <div class="mt-12">
              <mat-checkbox name="hasAcceptedTerms" required ngModel class="example-margin font-weight-600 light-color">
                <h3 class="m-0 font-weight-600">
                  I agree to
                  <a *ngIf="tenant.termsOfServiceLink" class="underline" target="_blank" [href]="tenant.termsOfServiceLink">Terms of Service</a>
                  <span *ngIf="!tenant.termsOfServiceLink">Terms of Service</span>
                </h3>
              </mat-checkbox>
            </div>
            <div>
              <button
                [disabled]="f.invalid"
                [class.disabled]="f.invalid"
                class="my-12 h-44 w-100-p font-size-18 register-butn"
                fxLayout="row"
                required
                fxLayoutAlign="center center"
                (click)="submitFormTwo(f)"
                style="cursor: pointer"
              >
                Complete Your Profile
                <mat-icon *ngIf="isLoadingFlag" style="background: lightblue; border-radius: 50%; margin-left: 5px">
                  <mat-spinner diameter="20"> </mat-spinner>
                </mat-icon>
              </button>
            </div>
          </form>

          <form *ngIf="isResendVerification" #f="ngForm" class="w-100-p pl-24 pt-20 fields-form">
            <div fxLayout="row" fxLayoutAlign="center center" class="w-100-p font-weight-600 blue-color font-size-18">Email Verification Needed</div>
            <div class="mt-12" fxLayout="column" fxLayoutAlign="start start" style="font-size: 17px">
              <p style="margin-bottom: 2px">An confirmation link has been sent to your mailbox</p>
              <p style="margin: 0; padding: 0">
                <a style="text-transform: none" href="mailto:{{ user.email }}">{{ user.email }}</a>
              </p>
              <p>In order to complete the sign-up process, please click the confirmation link.</p>
              <p>If you do not receive a confirmation email, please check your spam folder.</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="end end" style="padding: 15px; margin-right: 15px" class="w-100-p font-weight-600 blue-color font-size-18">
              <a class="nav-link" style="cursor: pointer" (click)="resendVerification()">
                Resend
                <mat-icon *ngIf="isLoadingFlag" style="background: lightgray">
                  <mat-spinner diameter="20"> </mat-spinner> </mat-icon
              ></a>
            </div>
          </form>
        </div>
      </div>

      <div class="w-100-p main-box" *ngIf="step === 1">
        <div fxFlex="30" class="h-100-p inner-left-box" fxLayout="column" fxLayoutAlign="center start">
          <div class="element-center max-width-300">
            <div class="p-20 w-100-p" fxLayout="column" fxLayoutAlign="center center">
              <img src="https://passets.wajooba.com/img/Onboarding_Placeholder.png" class="w-100-p" />
            </div>
            <div class="mt-12 w-100-p" fxLayout="column" fxLayoutAlign="center center">
              <h1 class="m-0 font-size-20">Let's configure your studio</h1>
            </div>
            <div class="mt-12 w-100-p" fxLayout="column" fxLayoutAlign="center center">
              <h1 class="m-0 font-size-20 font-weight-900 blue-color">{{ user.orgId }}</h1>
            </div>
            <div class="w-100-p mt-12" fxLayout="column" fxLayoutAlign="center center">
              <h4 class="element-center">{{ user.name }}</h4>
            </div>
            <div class="w-100-p my-12" fxLayout="column" fxLayoutAlign="center center">
              <h4 class="element-center">{{ user.email }}</h4>
            </div>
          </div>
        </div>
        <div fxFlex="70" class="h-100-p inner-right-box border-left" fxLayout="column" fxLayoutAlign="start start">
          <form #f="ngForm" class="w-100-p pl-24 pt-20 fields-form">
            <div fxLayout="row" fxLayoutAlign="center center" class="w-100-p font-weight-600 font-size-22">Business Information</div>
            <div class="mt-12" fxLayout="column" fxLayoutAlign="start start">
              <!-- <h3 class="m-0">Upload logo
                            </h3>
                            <div class="mt-12" fxLayout="row" fxLayoutAlign="start start">
                                <img class="h-40 w-100" src="{{picture}}">
                                <input hidden (change)="onChangeLogo($event.target.files)" id="upload" type="file"
                                    accept="image/*">
                                <button (click)="uploadLogo()" class="w-100 h-40 ml-20 upload-butn">Upload</button>
                            </div> -->

              <h3 class="m-0">Upload logo</h3>
              <app-file-upload [photos]="bannerImage" (getFileObject)="bannerImage$.next($event)" [title]="'Always a good idea to add an logo'"> </app-file-upload>
            </div>

            <div class="mt-12" fxLayout="column" fxLayoutAlign="start start">
              <h3 class="m-0 font-weight-600">Your Website URL</h3>
              <h5 class="m-0 font-weight-100">Your Website address</h5>
              <input
                class="mt-24 h-44 w-100-p pl-16 div-box"
                name="website"
                type="text"
                [class.invalid-border]="!patterns('link', website.value) && websiteCtrl.touched"
                ngModel
                #website
                placeholder="https://www.xyz.com"
                #websiteCtrl="ngModel"
              />
            </div>

            <div class="mt-12" fxLayout="column" fxLayoutAlign="start start">
              <h3 class="m-0 font-weight-600">
                TimeZone
                <sup class="mandatory-star">*</sup>
              </h3>
              <h5 class="m-0 font-weight-100">Your timeZone</h5>

              <select name="timezone" [ngModel]="'default'" #timezone required ngModel #timezonesCtrl="ngModel" class="mt-24 h-44 w-100-p pl-16 div-box">
                <option value="default" selected>Select TimeZone</option>
                <option [value]="zone.value" *ngFor="let zone of timeZones">{{ zone.text }}</option>
              </select>
            </div>

            <div class="mt-12" fxLayout="column" fxLayoutAlign="start start">
              <h3 class="m-0 font-weight-600">
                Business address
                <sup class="mandatory-star">*</sup>
              </h3>
              <h5 class="m-0 font-weight-100">Will be used as a part of your invoice and other communications</h5>
              <input
                class="mt-24 h-44 w-100-p pl-16 div-box"
                name="businessAddress"
                type="text"
                required
                [class.invalid-border]="businessCtrl.invalid && businessCtrl.touched"
                ngModel
                [pattern]="patterns('text')"
                placeholder="enter your business address"
                #businessCtrl="ngModel"
              />
            </div>

            <!-- <div class="mt-12" fxLayout="column" fxLayoutAlign="start start">
              <h3 class="m-0 font-weight-600">Guardian Support</h3>
              <h5 class="m-0 font-weight-100">Would like to have Guardians for your members, Guardians can then login and take care of member profiles from their logn email</h5>
              <mat-checkbox name="guardianSupport" ngModel class="mt-12 example-margin font-weight-600 light-color">
                <h3 class="m-0 font-weight-600">Do you have members below Age 18?</h3>
              </mat-checkbox>
            </div> -->
            <div class="my-24" fxLayout="row" fxLayoutAlign="end center">
              <button
                [disabled]="f.invalid || timezone.value === 'default' || !patterns('link', website.value)"
                [class.disabled]="f.invalid || timezone.value === 'default' || !patterns('link', website.value)"
                class="my-12 h-44 w-200 font-size-18 step2-butn"
                fxLayout="row"
                style="cursor: pointer"
                fxLayoutAlign="center center"
                (click)="submitFormOne(f)"
              >
                Complete Your Profile
                <mat-icon *ngIf="isLoadingFlag" style="background: lightblue; border-radius: 50%; margin-left: 5px">
                  <mat-spinner diameter="20"> </mat-spinner>
                </mat-icon>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
