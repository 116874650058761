import { Component, OnInit } from "@angular/core";
import { environment } from "environments/environment";
import * as moment from "moment-timezone";
import { TenantUserService } from "app/core/services/tenantuser.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { AppSettingsService } from "app/main/admin/app-settings/services/app-settings.service";
import { SubSink } from "subsink";
import { AppConstants } from "app/core/settings/appconstants";
import {AppLoadService} from "../../../core/services/appload.service";
import {TenantPing} from "../../../core/models/tenantPing";

@Component({
  selector: "footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  envName = `${environment.version}`;
  lastLoginTimeStr: any;
  pages = [];
  isPublicView: boolean = true;
  subs = new SubSink();
  _tenantConfig: TenantPing;

  /**
   * Constructor
   */
  constructor(private tenantUserService: TenantUserService, private appSettingsService: AppSettingsService, private _fuseNavigationService: FuseNavigationService, private _appLoadService: AppLoadService,) {}

  ngOnInit() {
    this._fuseNavigationService.onNavigationChanged.subscribe((key) => {
      if (key === AppConstants.INITIAL_EVENT || key === AppConstants.PUBLIC_NAVIGATION) {
        this.isPublicView = true;
        return;
      } else {
        this.isPublicView = false;
        this.getVersion();
      }
    });
    const lastLoginTime = moment().format("ddd MMM-DD-YYYY hh:mm A"); //stores the login activity
    this.lastLoginTimeStr = lastLoginTime;
    this._appLoadService.onTenantConfigChanged.subscribe((tenantConfig: any) => {
      this._tenantConfig = tenantConfig;
      for (let i = 0; i < this._tenantConfig.web.length; i++) {
        const element = this._tenantConfig.web[i];
        this.pages.push(element);
      }
      this.pages.sort((a, b) => a.sequence - b.sequence);
    });
  }

  getVersion() {
    this.subs.add(
      this.appSettingsService.getAppVersion().subscribe(
        (version: any) => {
          this.envName = version;
        },
        (error) => {}
      )
    );
  }
}
