<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0 accent">
    <mat-toolbar-row fxLayoutAlign="space-between center" style=" display: flex; justify-content: space-between; ">
      <span class="title dialog-title">{{ dialogTitle }}</span>
      <button mat-dialog-close mat-icon-button aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-dialog-content class="p-24 pb-0 m-0" style="margin-top:25px; margin-bottom:25px">
    <div style="width: 100%;">
      <form [formGroup]="customFieldForm" fxLayout="column" fxLayoutAlign="center" fxFlex="grow" style="width: 100%">
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Name</mat-label>
            <input (input)="generateTag($event)" #name formControlName="name" matInput required />
          </mat-form-field>
        </div>
        
        <div fxLayout="row" fxLayoutAlign="start start" class="mb-16">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Tag</mat-label>
            <input name="tag" #tag formControlName="tag" matInput required/>
            <mat-hint>Only Lowercase Alphabets and Numbers are allowed</mat-hint>
            <mat-error>
              <span *ngIf="customFieldForm.get('tag').errors?.['nameInList']">Tag is already in use</span>
              <span *ngIf="customFieldForm.get('tag').errors?.['patternMismatch']">Tag can't be empty and use lowercase without spaces</span>
              <span *ngIf="customFieldForm.get('tag').errors?.['maxlength']">Tag cannot exceed 25 chars</span>
            </mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="typeData == 'all'" fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex="100" matNativeControl style="width: 100%;">
            <mat-label>Type</mat-label>
            <mat-select name="type" formControlName="type" (selectionChange)="typeChanged($event.value)">
              <mat-option *ngFor="let option of allType" [value]="option.key">
                {{ option.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="typeData != 'all'" fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Type</mat-label>
            <input name="type" formControlName="type" [value]="type" matInput readonly />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start" *ngIf="isShowOptionField()">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Option 1</mat-label>
            <input formControlName="option1" matInput />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start" *ngIf="isShowOptionField()">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Option 2</mat-label>
            <input formControlName="option2" matInput />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start" *ngIf="isShowOptionField()">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Option 3</mat-label>
            <input formControlName="option3" matInput />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start" *ngIf="isShowOptionField()">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Option 4</mat-label>
            <input formControlName="option4" matInput />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start" *ngIf="isShowOptionField()">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Option 5</mat-label>
            <input formControlName="option5" matInput />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start" *ngIf="isShowOptionField()">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Option 6</mat-label>
            <input formControlName="option6" matInput />
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <span class="custom-control" fxFlex="100">Help text field name</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Help</mat-label>
            <input formControlName="placeholder" matInput />
          </mat-form-field>
        </div>

        <!-- <div fxLayout="row" fxLayoutAlign="start start">
          <mat-checkbox class="mt-10" formControlName="isFormField">
            Is Form Field
          </mat-checkbox>
        </div>
        <div
          fxLayout="row wrap"
          fxLayoutAlign="center"
          fxFlex="1 0 auto"
          style="margin-left: 24px;
        margin-bottom: 10px;"
        >
          <span class="custom-control" fxFlex="100">This is form field</span>
        </div> -->

        <div fxLayout="row" fxLayoutAlign="start start" *ngIf="isShowIsMandatoryField()">
          <mat-checkbox class="mt-10" formControlName="isMandatory">
            Is Mandatory
          </mat-checkbox>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-divider style="margin-top:25px;margin-bottom:3px"></mat-divider>

  <mat-dialog-actions style="float:right;margin-right: 25px;">
    <button mat-raised-button mat-dialog-close tabindex="-1" style="margin-right: 10px;">Cancel</button>
    <button class="mat-accent m-0" mat-raised-button tabindex="1" (click)="matDialogRef.close(this.customFieldForm)" [disabled]="customFieldForm.invalid" aria-label="Submit">
      Submit
      <mat-icon *ngIf="isLoading">
        <mat-spinner diameter="20"> </mat-spinner>
      </mat-icon>
    </button>
  </mat-dialog-actions>
</div>
