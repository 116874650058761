export class CustomField {
    guId: string;
    name: string;
    tag: string;
    option1: string;
    option2: string;
    option3: string;
    option4: string;
    option5: string;
    option6: string;
    placeholder: string;
    text: string;
    type: string;
    isMandatory: boolean;
    isFormField: boolean;
    typeData:string;

    constructor(customField: any) {
        this.guId = customField?.guId ?? "";
        this.name = customField?.name ?? "";
        this.tag = customField?.tag ?? "";
        this.option1 = customField?.option1 ?? "";
        this.option2 = customField?.option2 ?? "";
        this.option3 = customField?.option3 ?? "";
        this.option4 = customField?.option4 ?? "";
        this.option5 = customField?.option5 ?? "";
        this.option6 = customField?.option6 ?? "";
        this.placeholder = customField?.placeholder ?? "";
        this.text = customField?.text ?? "";
        this.type = customField?.type ?? "";
        this.isMandatory = customField?.isMandatory ?? false;
        this.isFormField = customField?.isFormField ?? false;
        this.typeData = customField?.typeData ?? "all";

    }
}
