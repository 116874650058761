import {Component, HostListener, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as _ from "lodash";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { navigation } from "app/navigation/navigation";

import { TenantUserService } from "app/core/services/tenantuser.service";
import { TUser } from "app/core/models/tenantuser";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { ContactdialogComponent } from "app/layout/components/contactdialog/contactdialog.component";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { AppUtilsService } from "app/core/services/apputils.service";
import { AppConstants } from "app/core/settings/appconstants";
import { Router, ActivatedRoute } from "@angular/router";
import { NoticeService } from "app/main/shared/components/notice/notice.service";
import { NoticeComponent } from "app/main/shared/components/notice/notice.component";
import { AppSettingsService } from "app/main/admin/app-settings/services/app-settings.service";
import { ContactdialogService } from "app/layout/components/contactdialog/contactdialog.service";
import { AppLoadService } from "app/core/services/appload.service";

@Component({
  selector: "toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  // selectedLanguage: any;
  userStatusOptions: any[];
  user: TUser;
  isStaffRole: boolean = false;
  isStudentPreview: boolean = false;
  isStudentfRole: boolean = false;
  isShowMasterTenantMode: boolean = false;
  notices = [];
  quotes = [];
  showMoreNotices: boolean;
  showMoreQuotes: boolean;
  franchiseData: any = [];
  isShowAllNotes: boolean = false;
  isShowFeedback: boolean = false;
  _imagePrefixPath: string;
  // Private
  private _unsubscribeAll: Subject<any>;
  dialogRef: any;
  _tenantConfig: any;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    // private _translateService: TranslateService,
    private _tenantUserService: TenantUserService,
    private dialog: MatDialog,
    private _fuseNavigationService: FuseNavigationService,
    private _router: Router,
    private route: ActivatedRoute,
    private noticeService: NoticeService,
    private _settingsService: AppSettingsService,
    private contactdialogService: ContactdialogService,
    private _appLoadService: AppLoadService
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: "Online",
        icon: "icon-checkbox-marked-circle",
        color: "#4CAF50"
      },
      {
        title: "Away",
        icon: "icon-clock",
        color: "#FFC107"
      },
      {
        title: "Do not Disturb",
        icon: "icon-minus-circle",
        color: "#F44336"
      },
      {
        title: "Invisible",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#BDBDBD"
      },
      {
        title: "Offline",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#616161"
      }
    ];

    this.languages = [
      {
        id: "en",
        title: "English",
        flag: "us"
      },
      {
        id: "tr",
        title: "Turkish",
        flag: "tr"
      }
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  myDonations() {
    this._router.navigate(["/" + AppConstants.MYDONATION_ROUTE], { relativeTo: this.route });
  }

  showAllQuotes() {
    this.isShowAllNotes = !this.isShowAllNotes;
  }
  backToAdminView(): void {
    this._tenantUserService.setStudentUser(null);
    this._tenantUserService.initUser(true);
  }

  showDialog(): void {
    const dialogRef = this.dialog.open(ContactdialogComponent, {
      panelClass: "contact-search-dialog",
      disableClose: true,
      width: "500px",
      height: "230px"
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {});
  }

  webPreview(): void {
    this._tenantUserService.setPreviewMode(true);
  }

  navigateToUserSettings() {
    this._router.navigate([AppConstants.USER_SETTINGS]);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
    console.log(value);
  }

  /**
   * Set the language
   *
   * @param lang
   */
  // setLanguage(lang): void {
  //   // Set the selected language for the toolbar
  //   // this.selectedLanguage = lang;

  //   // Use the selected language for translations
  //   // this._translateService.use(lang.id);
  // }

  logoutUser() {
    this._tenantUserService
      .logout()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        console.log("user logged out");
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(settings => {
      this.horizontalNavbar = settings.layout.navbar.position === "top";
      this.rightNavbar = settings.layout.navbar.position === "right";
      this.hiddenNavbar = settings.layout.navbar.hidden === true;
    });

    // Set the selected language from default languages
    // this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });

    //Set the applicaiton layout and navigation when user changes
    this._tenantUserService.user.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: TUser) => {
      this.user = user;
      this.isStaffRole = TenantUserService.isStaff(this.user);
      this.isStudentfRole = TenantUserService.isStudent(this.user);
      this.isStudentPreview = TenantUserService.isStudentPreview(this.user);
      //this.getNotices();
      this.loadFranchise();
    });

    this._appLoadService.onTenantConfigChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(tenantConfig => {
      this._imagePrefixPath = "https://res.cloudinary.com/" + tenantConfig.cloudName + "/image/upload/";
      this._tenantConfig = tenantConfig;
    });

    this.isShowMasterTenantMode = this._tenantUserService.getIsFranchiseLoginMode() == null ? false : true;
  }

  getNotices() {
    this.noticeService.getNotices(this.isStudentPreview).subscribe((response: any) => {
      const notices = [];
      const quotes = [];
      response.data.forEach(notice => {
        if (notice.isActive) {
          if (notice.type === "notice") {
            notices.push(notice);
          } else if (notice.type === "quote") {
            quotes.push(notice);
          }
        }
      });
      this.notices = notices;
      this.quotes = quotes;
    });
  }

  createNotice() {
    if (!this.isStudentPreview) {
      this.showCreateNoticeDialog();
    }
  }

  showCreateNoticeDialog(): void {
    this.dialogRef = this.dialog.open(NoticeComponent, {
      panelClass: "dlg-contct-div"
    });
    this.dialogRef.afterClosed().subscribe((response: any) => {
      this.getNotices();
    });
  }

  navigateToProfile() {
    this._router.navigate(["/" + AppConstants.CONTACTS_ROUTE + "/" + this.user.guId + "/detail"], {
      relativeTo: this.route
    });
  }

  loadFranchise() {
    this._settingsService.getFranchises().subscribe((response: any) => {
      this.franchiseData = response.data;
    });
  }

  showFranchise(franchise) {
    this.getTanantToken(franchise);
  }

  getTanantToken(franchise): void {
    this.contactdialogService.getTenantToken(franchise.guid, "").subscribe((token: any) => {
      this._tenantUserService.setAuthToken(token);
      this._tenantUserService.setIsFranchiseLoginMode("true");
      this._tenantUserService.setFranchiseLoginGuId(this.user.guId);
      this._tenantUserService.initUser(true);
      window.location.href = window.location.origin + "/dashboard";
    });
  }
  backToAdminMode() {
    this.contactdialogService
      .getTenantToken(this._tenantConfig.orgGuId, this._tenantUserService.getFranchiseLoginGuId())
      .subscribe((token: any) => {
        // this._tenantUserService.setStudentUser(token);
        this._tenantUserService.setAuthToken(token);
        this.isShowMasterTenantMode = false;
        this._tenantUserService.removeFranchiseLoginMode();
        this._tenantUserService.removeFranchiseLoginGuId();
        window.location.href = window.location.origin + "/dashboard";
      });
  }

  showNotices() {}
  @HostListener("window:resize", []) windowChanged() {
    this._fuseSidebarService.getSidebar('navbar').close();
  }
}
