import { Component, OnInit, ViewEncapsulation, EventEmitter } from "@angular/core";
import { Router, RouterState } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { FuseConfigService } from "@fuse/services/config.service";
import { TenantUserService } from "app/core/services/tenantuser.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SubSink } from "subsink";
import { TimeZone } from "app/main/shared/enums/time-zone";
import { NgForm } from "@angular/forms";
import { AppConstants } from "app/core/settings/appconstants";
import { Tenant } from "app/core/models/tenant";
import { TUser } from "app/core/models/tenantuser";
import { TenantService } from "app/core/services/tenant.service";
import { OnboardingService } from "./onboarding.service";
import { AppUtilsService } from "app/core/services/apputils.service";
import { ToastrService } from "ngx-toastr";
import { interval, Subscription } from "rxjs";
import { FileObject } from "app/main/shared/components/file-upload/file-upload.component";
import { ProductService } from "app/main/admin/products/services/product.service";
import { take, takeUntil } from "rxjs/operators";
import { AppLoadService } from "app/core/services/appload.service";
import { CheckoutUtilService } from "app/main/public/checkout/checkout.util.service";

@Component({
  selector: "app-onboarding",
  templateUrl: "./onboarding.component.html",
  styleUrls: ["./onboarding.component.scss"],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class OnboardingComponent implements OnInit {
  token: any;
  userInfo: any;
  subs = new SubSink();
  step = 1;
  isLoading: boolean;
  picture: any;
  address: any;
  timeZones = [];
  stateInformation: any;
  payload: {};
  user: any;
  tenant: any;
  profileScreen: any;
  phone: any;
  isResendVerification: boolean = false;
  isValidPhone: boolean = false;
  isLoadingFlag: boolean = false;
  errorMsg: any = "";
  intervalTime: any;

  bannerImageUrl: string;
  bannerImage: { id: number; url: string }[] = [];
  bannerImage$: EventEmitter<FileObject[]> = new EventEmitter<FileObject[]>();

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _tenantUserService: TenantUserService,
    private _tenantService: TenantService,
    private _onboardingService: OnboardingService,
    private SpinnerService: NgxSpinnerService,
    private _router: Router,
    public toastr: ToastrService,
    private productService: ProductService,
    private appLoadService: AppLoadService,
    private checkoutUtilService: CheckoutUtilService
  ) {}

  ngOnInit(): void {
    this.initBannerImage();
    this.hideApp(true);
    this.getTenantUserService();

    this.timeZones = TimeZone;
  }

  initBannerImage() {
    this.subs.add(
      this.bannerImage$.subscribe(res => {
        this.bannerImage = res;
        let list = this.bannerImage[0].url.split("/");
        let length = list.length;
        this.bannerImageUrl = `${list[length - 2]}/${list[length - 1]}`;
        this.picture = this.bannerImageUrl;
      })
    );
  }

  getTenantUserService() {
    this.isLoading = true;
    this.SpinnerService.show();
    this.subs.add(
      this._tenantUserService.user.subscribe((user: TUser) => {
        console.log("user", user);
        this.user = user;
        const isStudentRole = TenantUserService.isStudent(this.user);

        if (!this.user.isFirstLogin && isStudentRole) {
          if (this.user.isEmailVerified) {
            this.stopInterval();
            this.redirectState();
          } else {
            this.step = 2;
            this.isResendVerification = true;
            this.resendVerification();
            this.intervalTime = setInterval(() => {
              if (!this.user.isEmailVerified) {
                this.isVerificationCheck();
              } else {
                this.redirectState();
              }
            }, 10000);
          }
        }
        this.getTenantService();
      })
    );
  }

  setProfileScreenDetails() {
    this.profileScreen = {
      name: this.user.fullName,
      phone: this.user.phone,
      hasAcceptedTerms: this.user.hasAcceptedTerms,
      isWaiverFormToBeSigned: this.tenant.isWaiverFormToBeSigned
    };
  }

  getCountryCode(tenant) {
    this.phone = AppUtilsService.getCountryDialCode(tenant.timezone) + "-";
  }
  getTenantService() {
    this.subs.add(
      this._tenantService.tenant$.subscribe((tenant: Tenant) => {
        console.log("tenant", tenant);
        this.getCountryCode(tenant);
        this.tenant = tenant;
        this.setProfileScreenDetails();
        this.picture = this.tenant.smallLogo;
        this.address = this.tenant.address;
        if (this.picture) {
          this.productService
            .getcloudinaryBaseURL()
            .pipe(take(1))
            .subscribe(baseURL => {
              console.log("baseURL", baseURL);
              console.log("this.picture", this.picture);
              let bannerImg = `${baseURL}${this.picture}`;
              if (this.picture.startsWith("https") || this.picture.startsWith("http")) {
                bannerImg = this.picture;
              }
              this.bannerImage.push({
                id: 1,
                url: bannerImg
              });
            });
        }
        const isStaff = this._tenantUserService.isStaffRole();
        if (this.tenant.isNewTenant && isStaff) {
          this.step = 1;
        } else if (this.user.isFirstLogin) {
          this.step = 2;
        } else if (!this.user.isEmailVerified && this._tenantUserService.isStudentRole()) {
          this.step = 2;
          this.isResendVerification = true;
        } else {
          this._router.navigate([AppConstants.SL_CLASSES_ROUTE]);
          this.hideApp(false);
        }
        this.isLoading = false;
        this.SpinnerService.hide();
      })
    );
  }

  private hideApp(isHide: boolean, isNotify: boolean = true) {
    // Configure the layout
    this._fuseConfigService.setConfig(
      {
        layout: {
          navbar: {
            hidden: isHide
          },
          toolbar: {
            hidden: isHide
          },
          footer: {
            hidden: isHide
          },
          sidepanel: {
            hidden: isHide
          }
        }
      },
      isNotify
    );
  }

  isVerificationCheck() {
    console.log("isVerificationCheck");
    this._onboardingService.getContactDetails(this.user.guId).subscribe(user => {
      console.log(user);
      this.user = user;
      if (!user) {
        this.stopInterval();
        return;
      }
      if (this.user.isEmailVerified) {
        this.stopInterval();
        this.redirectState();
      }
    });
  }

  stopInterval = function() {
    clearInterval(this.intervalTime);
  };

  redirectState() {
    this._router.navigate([AppConstants.SL_CLASSES_ROUTE]);
    this.hideApp(false);
  }

  submitFormTwo(form: NgForm) {
    console.log(form);
    const payload = {};
    let lastname = "";
    let firstname = "";
    var tempName = form.value.name.split(" ");
    if (tempName && tempName.length > 1) {
      lastname = this.checkoutUtilService.getLastName(tempName);
      firstname = tempName[0];
    } else {
      firstname = form.value.name;
    }

    payload["name"] = firstname;
    payload["lastName"] = lastname;
    payload["phone"] = this.phone;
    payload["phoneNumber"] = this.phone;
    payload["address"] = {};
    payload["address"]["line1"] = form.value.address;
    payload["isWaiverFormToBeSigned"] = form.value.isWaiverFormToBeSigned;
    payload["hasAcceptedTerms"] = form.value.hasAcceptedTerms;
    payload["isFirstLogin"] = false;
    this.validatePhone();
    console.log(this.isValidPhone, payload);
    if (this.isValidPhone) {
      return;
    }
    /*if(!this.user.isEmailVerified && this._tenantUserService.isStudentRole()){
       this.isResendVerification = true; 
       this.intervalTime = setInterval(() => {
        if (!this.user.isEmailVerified) {
          this.isVerificationCheck()
        } else {
          this.redirectState()
        }
       }, 10000); 
    }else{
      this.redirectState()
    } */
    this.isLoadingFlag = true;
    this._onboardingService.updateTenantUserService(payload, this.user.guId).subscribe(tenantResponse => {
      console.log(tenantResponse);
      this.isLoadingFlag = false;
      this.appLoadService.setTenantConfig(tenantResponse);
      if (!this.user.isEmailVerified && this._tenantUserService.isStudentRole()) {
        this.resendVerification();
        this.isResendVerification = true;
        this.intervalTime = setInterval(() => {
          if (!this.user.isEmailVerified) {
            this.isVerificationCheck();
          } else {
            this.redirectState();
          }
        }, 10000);
      } else {
        this.redirectState();
      }
    });
  }

  resendVerification() {
    if (!this.user.isEmailVerified) {
      let redirectUrl = "https://" + window.location.hostname;
      let payload = {
        redirectUrl: redirectUrl
      };
      this.isLoadingFlag = true;
      this._onboardingService.resendVerification(payload, this.user.guId).subscribe(contactResponse => {
        console.log(contactResponse);
        this.isLoadingFlag = false;
        this.toastr.success("Sent verification email");
      });
    }
  }
  submitFormOne(form: NgForm) {
    this.isLoadingFlag = true;
    const payload = {};
    if (this.picture.startsWith("http")) {
      let list = this.picture.split("/");
      let length = list.length;
      this.bannerImageUrl = `${list[length - 2]}/${list[length - 1]}`;
      this.picture = this.bannerImageUrl;
    }
    payload["logoUrl"] = this.picture;
    payload["companyUrl"] = form.value.website;
    payload["timezone"] = form.value.timezone;
    payload["address"] = {};
    payload["address"]["line1"] = form.value.businessAddress;
    payload["isShowGuardian"] = form.value.guardianSupport;
    payload["isNewTenant"] = false;
    console.log(payload);
    this._onboardingService.updateTenantService(payload).subscribe(tenantResponse => {
      this._tenantService.populateTenant(tenantResponse);
      console.log(tenantResponse);
      this.isLoadingFlag = false;
      if (this.user.isFirstLogin) {
        this.step = 2;
      } else {
        this._router.navigate([AppConstants.SL_CLASSES_ROUTE]);
        this.hideApp(false);
      }
    });
  }

  patterns(type, value: any) {
    let pattern = "s*(Ss*){2,}";
    if (type === "email") {
      pattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    } else if (type === "number") {
      pattern = "[0-9+]{10,12}";
    } else if (type === "text") {
      pattern = "\\s*(\\S\\s*){2,}";
    } else if (type === "link") {
      if (!value) {
        return true;
      }
      var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      var regex = new RegExp(expression);
      return value.match(regex);
    }
    return pattern;
  }

  uploadLogo() {
    document.getElementById("upload").click();
  }

  onChangeLogo(files: FileList) {
    this.uploadFiles(files);
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = _event => {
      this.picture = String(reader.result);
    };
  }

  uploadFiles(files) {
    if (!files) return;
    const file = files[0];
    if (file) {
      const payload = {
        upload_preset: this.tenant.cloudinaryPreset,
        tags: "orgs",
        context: "photo=",
        file: file,
        folder: this.tenant.orgId +'-'+ this.tenant.tenantId,
      };
      this._onboardingService
        .uploadLogo(this.tenant.cloudinaryCloudName, payload)
        .subscribe((uploadLogoResponse: any) => {
          console.log(uploadLogoResponse);
          this.picture = uploadLogoResponse.signedurl;
        });
    }
  }

  validatePhone() {
    this.errorMsg = "";
    if (this.phone === "") {
      this.isValidPhone = true;
    } else {
      //console.log(this.phone.split("-")[0], this.phone.split("-")[1])
      if (this.phone.split("-")[0] === "") {
        this.errorMsg = "Please pick country code";
        this.isValidPhone = true;
      } else if (this.phone.split("-")[1] === "") {
        this.isValidPhone = true;
      } else {
        this.isValidPhone = false;
        this.errorMsg = "";
      }
    }
  }

  getPhoneNumber(phoneNumber: string) {
    this.phone = phoneNumber;
    this.profileScreen.phone = this.phone;
    console.log("phone ", this.phone);
    this.validatePhone();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.stopInterval();
  }
}
