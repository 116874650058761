import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl } from "@angular/forms";
import { Observable, EMPTY, Subject } from "rxjs";
import {
  map,
  startWith,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
  catchError,
  takeUntil
} from "rxjs/operators";
import { TenantUserService } from "app/core/services/tenantuser.service";
import { ContactdialogService } from "./contactdialog.service";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: "app-contactdialog",
  templateUrl: "./contactdialog.component.html",
  styleUrls: ["./contactdialog.component.scss"]
})
export class ContactdialogComponent implements OnInit {
  contactSearchControl = new FormControl();
  searchContacts: Observable<string[]>;
  guId: string;
  private _unsubscribeAll: Subject<any>;
  isLoading: boolean;
  isSearchLoading:boolean = false;
  type: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ContactdialogComponent>,
    private tenantUserService: TenantUserService,
    private contactdialogService: ContactdialogService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.type = this.data?.type;
    this.searchContacts = this.contactSearchControl.valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(search => this.getSearchResult(search)), 
      map(data => {
        this.isSearchLoading = false;
        return data;
        /*if(Array.isArray(data))
          return data.slice(0, data.length - 1);
        else
          return []*/
      })
    );
  }

  
getSearchResult(search: any) {
  this.isSearchLoading = true;
  if (search) {
      return this.contactdialogService.contactSearch(search).pipe(
          catchError(err => {
              return EMPTY;
          })
      );
  } else {
      this.isSearchLoading = false;
      return [];
  }
}

  setContactID(id) {
    this.guId = id;
  }

  getContactToken(): void {
    this.isLoading = true;
    if (this.type === 'store') {
      this.dialogRef.close(this.guId);
      return;
    } 

    if (this.type === 'invoice') {
      this.dialogRef.close(this.guId);
      return;
    } 

    this.contactdialogService
      .getContactToken(this.guId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(token => {
        this.tenantUserService.setStudentUser(token);
         this.tenantUserService.initUser(true);
        this.isLoading = false;
        this.dialogRef.close(true);
        // this.router.navigate(['/'], { relativeTo: this.route});
      });
  }


  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
