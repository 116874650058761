export class TUser {
  guId: string = "";
  email: string = "";
  name: string = "";
  fullName: string = "";
  lastName: string = "";
  phone: string = "";
  role: string = "";
  picture: string = "";
  isLoggedIn: boolean = false;
  orgId: string = "";
  hasAcceptedTerms: boolean;
  isPreviewMode: boolean = false;
  isStudentPreview: boolean = false;
  studentToken: string = "";
  isNewClient: boolean;
  isFirstLogin: boolean;
  isEmailVerified: boolean;
  isAdminVerified: boolean;
  isPreviewOfSecuredPage: boolean = false;

  constructor(orgId?: string) {
    if (orgId) {
      this.orgId = orgId;
    }
  }
}
